import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { DownloadService } from '../../services/download/download.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { FormatNumberPipe } from "../../helpers/pipes/format-number/format-number.pipe";
import { ConvertSizePipe } from "../../helpers/pipes/convert-size/convert-size.pipe";
import { SharedService } from '../../services/shared/shared.service';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MathAbsPipe } from "../../helpers/pipes/math-abs/math-abs.pipe";
// import { ExportService } from '../../services/export/export.service';
// import { ExportExcel } from '../../interfaces/Export';
// import { utils, writeFileXLSX  } from 'xlsx';

type DetailDossierListKeys = "dossiers-en-cours" | "dossiers-clos" | "messages-non-lus" | "dossiers-liste" | "dossiers-message" | "dossiers-document" | "documents-non-consultes"

const detailDossierList: Readonly<Record<DetailDossierListKeys, "suivi" | "messages" | "documents">> = 
{
  "dossiers-en-cours" : "suivi",
  "dossiers-clos" : "suivi",
  "messages-non-lus" : "messages",
  "dossiers-liste" : "suivi",
  "dossiers-message" : "messages",
  "dossiers-document" : "documents",
  "documents-non-consultes": "documents"
}


@Component({
    selector: 'app-table',
    standalone: true,
    templateUrl: './table.component.html',
    styleUrl: './table.component.css',
    imports: [CommonModule, MatTableModule, MatProgressSpinnerModule, MatSortModule, FormatNumberPipe, ConvertSizePipe, MathAbsPipe]
})
export class TableComponent<T>
{

  @Input() parent!:string
  @Input() data_header_table!:object
  @Input() data_body_table: Array<T> = []
  @ViewChild(MatSort) sort!: MatSort

  date:Date = new Date()
  ELEMENT_DATA:Array<T> = []
  ORIGINAL_DATA:Array<T> = []
  dataSource!:MatTableDataSource<T>
  displayedColumns!: string[]
  titleColumns!: string[]
  devise:string = "€"
  idUser!:number
  isDataReady:boolean = false
  detailList:string[] = ["dossiers","dossiers-en-cours","dossiers-clos","messages-non-lus","documents-non-consultes","dossiers-liste","dossiers-message","dossiers-document"]
  exclusion:string[] = ["telecharger","exporter",""]
  centerElementList:string[] = ["telecharger","exporter"]


  constructor
  (
    private downloadService: DownloadService,
    // private exportService: ExportService,
    private navigationService: NavigationService,
    private sharedService: SharedService,
    private router: Router
  ) 
  {
    const id_user = this.sharedService.getIdUser()
    this.idUser = id_user
  }

  ngOnChanges(changes: SimpleChanges) 
  {
    if ('data_header_table' in changes) 
    {
      this.initDataSource()
    }
    else if ('data_body_table' in changes) 
    {
      this.initDataSource()
    }
  }

  ngOnInit()
  {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(()=> {
      this.isDataReady = false
      this.initDataSource()
    })
  }

  ngAfterViewInit() 
  {
    this.dataSource.sort = this.sort
  }

  initDataSource()
  {
    this.displayedColumns = Object.keys(this.data_header_table)
    this.titleColumns = Object.values(this.data_header_table)

    this.ELEMENT_DATA = this.data_body_table
    this.isDataReady = true

    this.dataSource = new MatTableDataSource<T>(this.ELEMENT_DATA)
  }

  download_dossier_document(ref:string, fileName:string, id:number=0)
  {
    this.downloadService.downloadProcess(ref, fileName, 'dossiers', this.idUser, id)
  }

  markAsRead(id_document:number, id_user:number)
  {
    this.sharedService.markDocumentAsRead(id_document, id_user)
    this.dataSource.data.map((document:any) => {
      if(document.id_document == id_document)
      {
        document.non_lu = false
      }
    })
  }

  goToDetails(ref:string, str:string, id_dossier:number|null=null)
  {
    if(!ref) return

    const onglet = detailDossierList[(str as DetailDossierListKeys)]

    if(this.detailList.includes(this.parent)) 
    {
      this.navigationService.navigateTo(`dossiers/details/ref_${ref}/id_dossier_${id_dossier}/onglet_${onglet}`, [null])
    }
  }
}

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AlertService } from '../../services/alert/alert.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SafeHtmlPipe } from "../../helpers/pipes/safe-html/safe-html.pipe";

@Component({
    selector: 'app-alert',
    standalone: true,
    templateUrl: './alert.component.html',
    styleUrl: './alert.component.css',
    imports: [CommonModule, MatTooltipModule, SafeHtmlPipe]
})
export class AlertComponent 
{
  date:string = new Date().toISOString().slice(0,10)
  nb_alertes:number = 0
  nb_informations:number = 0
  isCarouselVisible:boolean = false
  isCarouselContainerVisible:boolean = false
  slides:{contenu:string, type_alerte:string}[] = []
  bgColor!:string
  textColor:string = "black"
  content!:string

  constructor
  (
    private alertService: AlertService
  ) {}

  ngOnInit()
  {
    this.getData()

    document.addEventListener('slide.bs.carousel', (event:any) => {
      if( event.target instanceof HTMLDivElement )
      {
        const classList = event.relatedTarget.className

        this.bgColor = classList.includes("alerte") ? 'bg-red' : 'bg-orange'
        this.textColor = classList.includes("alerte") ? 'red' : 'orange'
      }
    })
  }

  formatDate(date:Date)
  {
    return date.toString().slice(0,10)
  }

  getData()
  {
    this.alertService.getData().subscribe(data => {
      if(data)
      {
        const filteredData = data.filter(e => this.formatDate(e.date_debut) <= this.date && this.formatDate(e.date_fin) >= this.date)
  
        this.nb_alertes = filteredData.filter(e => e.type_alerte == 'alerte').length
        this.nb_informations = filteredData.filter(e => e.type_alerte == 'warning').length
  
        if(this.nb_alertes > 0 || this.nb_informations > 0) this.isCarouselContainerVisible = true
        
        this.slides = filteredData.map( e => {
            return {type_alerte:e.type_alerte, contenu:e.contenu.replaceAll("&nbsp;"," ")}
          })
          
        this.bgColor = this.slides[0]?.type_alerte == "alerte" ? 'bg-red' : 'bg-orange' 
        this.textColor = this.slides[0]?.type_alerte == "alerte" ? 'red' : 'orange'
      }
    })
  }

  toggleAlert()
  {
    this.isCarouselVisible = !this.isCarouselVisible
  }
}

import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { NavigationService } from '../services/navigation/navigation.service';
import { dialogNotAllowedText } from '../helpers/texts/texts';
import { environment } from '../../environments/environment';


@Component({
  standalone: true,
  imports: [CommonModule],
  template: `
    <form class="dialog d-flex border border-secondary">
      <div class="col-4 bg-blue white bold dialog-title">CONNEXION : <br><br> Accès non autorisé</div>
      <div class="col-8 bg-white blue-dark-1 dialog-content-group">
          <div class="dialog-content">
              ${dialogNotAllowedText}

              <p>
                En cas de besoin, vous pouvez utiliser le <a class="blue underline cursor" (click)="goToContactPage()">formulaire de contact de la Médiation de l'eau</a>
              </p>

              <div>
                <button 
                  type="button" 
                  class="bg-grey white no-border py-2 px-3 mt-2 mx-1 float-end" 
                  (click)="closeDialog()">
                    Fermer
                </button>
              </div>
          </div>
      </div>
    </form>
  `
})

export class NotAllowed
{
    constructor
    (
      private navigationService: NavigationService,
      private dialog: MatDialog,
    ) {}

    closeDialog()
    {
      this.dialog.closeAll()
    }

    goToContactPage()
    {
      const link = document.createElement("a")

      link.href = environment.CONTACT_LINK
      link.target = "_blank"
      link.click()
      // return this.sharedService.goToContactPage()
    }
}

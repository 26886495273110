import { Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Suivi } from '../interfaces/Suivi';
import { DossiersService } from '../services/dossiers/dossiers.service';
import { SharedModule } from '../modules/shared/shared.module';
import { Dossiers_Data } from '../interfaces/Dossiers';
import { SharedService } from '../services/shared/shared.service';

@Component({
  selector: 'app-suivi',
  standalone: true,
  imports: [SharedModule],
  template: `
      @if( !dataSource )
      {
        <div class="d-flex justify-content-center mt-3">
          <mat-spinner></mat-spinner>
        </div>
      }
      @else
      {
        <div class="mat-elevation-z0 border suivi-table-wrapper">
          <table mat-table [dataSource]="dataSource" class="suivi-table" matSort>
  
            <ng-container matColumnDef="warning">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <!-- <img src="/assets/icons/picto-warning-orange.png" height="15" alt="" *ngIf="element.hors_delai"> -->
              </td>
            </ng-container>
  
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
              <td mat-cell *matCellDef="let element" class="date">{{ element.date | date:'dd-MM-yyyy' }}</td>
            </ng-container>
  
            <ng-container matColumnDef="etat">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>État</th>
              <td mat-cell *matCellDef="let element" class="etat">{{ element.etat }}</td>
            </ng-container>
  
            <ng-container matColumnDef="sous_etat" *ngIf="data.type == 'partenariat'">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Sous État</th>
              <td mat-cell *matCellDef="let element" class="sous_etat">{{ element.sous_etat }}</td>
            </ng-container>
  
            <ng-container matColumnDef="indication">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Indication</th>
              <td mat-cell *matCellDef="let element;let i = index" class="indication">
              
              @if( i === 0 ) { {{ element.indication }} }
              @else if( i > 0 && element.indication ) 
              {
                <div class="tile table-tooltip bg-white blue orange">
                    <div class="indication grey">
                        <div class="icon">
                          <div class="toolTipAction"><img class="picto" src="/assets/img/picto-question.png" alt="question" height="15"></div>
                          
                          <div class="small bg-grey-light blue-dark-1 p-2 toolTipContent">
                            <img src="/assets/icons/picto-info.png" alt="" height="15"> {{ element.indication }}
                          </div>
                        </div>
                    </div>
                </div>
              }
  
            </td>
            </ng-container>
  
            <ng-container matColumnDef="date_limite_reponse">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Date limite de réponse</th>
              <td mat-cell *matCellDef="let element" class="date_limite_reponse">{{ (element?.date_limite_reponse | date:'dd-MM-yyyy') ?? "-" }}</td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-table-details"></tr>
            <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="content-table-details" [ngClass]="[ row.hors_delai ? 'orange bold' : '']"></tr> -->
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="content-table-details"></tr>
          </table>
      </div>
      
      <div class="accordion accordion-flush" id="accordion" *ngIf="accordion_text && accordion_text.length > 0">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button bg-blue white collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-container" aria-expanded="true" aria-controls="collapseOne">
              <div class="d-flex flex-column">
                @if (data.type === "dossiers") 
                {
                  <span>Résumé du litige</span>
                  <small>porté à la connaissance du Médiateur de l'eau par le requérant</small>
                }
                @else if (data.type === "partenariat") 
                {
                  <span>Option choisie</span>
                }
              </div>
            </button>
          </h2>
          <div id="accordion-container" class="accordion-collapse collapse border" data-bs-parent="#accordion" *ngIf="accordion_text.length > 0">
            <div class="accordion-body" [innerHTML]="accordion_text"></div>
          </div>
        </div>
      </div>
      }

  `,
  styles:`
    .suivi-table-wrapper
    {
      border: 1px solid #828282;
    }

    .suivi-table-wrapper .suivi-table td
    {
      padding: 1rem;
    }

    .table-tooltip
    {
      width: unset;
      height: unset;
      display: initial;
    }

    .table-tooltip .indication .icon
    {
      display: flex;
    }

    .table-tooltip .indication, .table-tooltip .indication .icon .text
    {
      position: relative;
      top: unset;
      right: unset;
    }

    .toolTipContent
    {
      opacity: 0;
      cursor:unset
    }

    .toolTipAction:hover ~ .toolTipContent
    {
      opacity: 1;
    }

    .accordion-body
    {
      word-wrap: break-word;
    }
  `
})

export class SuiviComponent 
{
  @Input() data!:Dossiers_Data
  @ViewChild(MatPaginator, {static:true}) paginator!: MatPaginator

  title:string = 'suivi'
  date:Date = new Date()
  id_user:number
  formatedDate = this.date.toLocaleDateString().split("/").join('-')
  accordion_text:string = ""
  displayedColumns!: string[]
  ELEMENT_DATA:Suivi[] = []
  dataSource!:MatTableDataSource<Suivi>
  
  constructor(
    private dossierService: DossiersService,
    private sharedService: SharedService
  ) 
  {
    this.id_user = this.sharedService.getIdUser()
  }
  
  ngOnInit()
  {
    this.getSuivi()
  }

  initDisplayedColumns()
  {
    this.displayedColumns = ["warning","date","etat","indication","date_limite_reponse"]
  }


  getSuivi()
  {
    this.initDisplayedColumns()
      this.dossierService.getSuivi(this.data.ref, this.id_user).subscribe(data => {
        // Remplace les points en fin de phrase sans affecter les autres types de points (chiffres, articles, etc...)
        // Il se peut qu'il y ait des cas non pris en compte
        // if(data[0].resume_litige) this.accordion_text = data[0].resume_litige.replace(/\.(\s+|$)(?=[a-zA-Z])/g, ". <br> <br>");
        if(data.length > 0) 
        {
          // this.accordion_text = data[0].resume_litige
          this.accordion_text = data[0].resume_litige
                                        // .replace(/[\n\r]/g, '<br>')
                                        // .replace(/\.(\s+|$)(?=[a-zA-Z])/g, ". <br> <br>");
        }
        this.ELEMENT_DATA = data
        this.dataSource = new MatTableDataSource<Suivi>(this.ELEMENT_DATA)
      })
  }
}

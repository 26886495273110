import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConnexionService } from '../services/connexion/connexion.service';
import { CommonModule } from '@angular/common';
import { SharedService } from '../services/shared/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { StorageService } from '../services/storage/storage.service';
import { NavigationService } from '../services/navigation/navigation.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { confirmPasswordValidator } from '../helpers/custom-validators/password.validator';
import { ProfilService } from '../services/profil/profil.service';


@Component({
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule],
  template: `
    <form class="dialog d-flex border border-secondary" [formGroup]="form">
      <div class="col-4 bg-blue white bold dialog-title">CONNEXION : <br><br> Changement de mot de passe</div>
      <div class="col-8 bg-white blue-dark-1 dialog-content-group">
          <div class="dialog-content">
            <p>Pour des raisons de sécurité, vous devez remplacer le mot de passe par défaut fourni par la Médiation de l'eau.</p>
            <p>La longueur de votre mot de passe doit être de 8 caractères au minimum</p>

            <div class="d-flex flex-column">
              <div>Votre nouveau mot de passe :</div>
              <li class="password-item">                
                <input 
                    class="form-control" 
                    type="password" 
                    formControlName="password" 
                    [maxlength]="limit"
                    [ngClass]="form.get('password')?.value.length >= limit ? 'red': ''">

                <img class="password-icon" [src]="srcIconPassword" alt="montrer ou masquer le nouveau mot de passe" (click)="togglePasswordVisibility($event)">

                <em class="red" *ngIf="isSubmitted && form.get('password')?.errors?.['minlength']"><p>* La longueur du mot de passe doit être de 8 caractères au minimum</p></em>
                <em class="red" *ngIf="form.get('password')?.value.length >= limit"><p>Vous avez atteint la limite du nombre de caractères autorisé</p></em>
              </li>
            </div>

            <br>

            <div class="d-flex flex-column">
              <div>Confirmez votre mot de passe :</div>
              <li class="password-item">
                <input 
                    class="form-control" 
                    type="password" 
                    formControlName="password_confirm" 
                    [maxlength]="limit"
                    [ngClass]="form.get('password_confirm')?.value.length >= limit ? 'red': ''">

                <img class="password-icon" [src]="srcIconPassword" alt="montrer ou masquer le nouveau mot de passe" (click)="togglePasswordVisibility($event)">

                <em class="red" *ngIf="isSubmitted && form.get('password_confirm')?.errors?.['minlength']"><p>* La longueur du mot de passe doit être de 8 caractères au minimum</p></em>
                <em class="red" *ngIf="form.get('password_confirm')?.value.length >= limit"><p>Vous avez atteint la limite du nombre de caractères autorisé</p></em>
              </li>         
            </div>

            <em class="red" *ngIf="isSubmitted && form.hasError('passwordMismatch')"><p>* Les mots de passe ne sont pas identiques</p></em>

            <br>

            <div>
                <button 
                    type="button" 
                    class="bg-blue white no-border py-2 px-3 mt-2 mx-1 float-end" 
                    (click)="submit()">
                    Valider
                </button>
            </div>
          </div>
      </div>
    </form>
  `,
  styles:
  `
    .password-item
    {
      display: flex;
      align-items: center;
    }

    .password-icon
    {
      height: 25px;
      margin-left: -2.5rem;
      cursor: pointer;
    }
  `
})

export class Password
{
  form!:FormGroup
  isSubmitted:boolean = false
  limit:number = Object.freeze(200)
  srcIconPassword:string = "/assets/icons/picto-oeil-cacher.png"
  hide_password:string = "/assets/icons/picto-oeil-cacher.png"
  show_password:string = "/assets/icons/picto-oeil-montrer.png"

  constructor
  (
      private profilService: ProfilService,
      private dialog: MatDialog,
      private snackBar: MatSnackBar,
      private navigationService: NavigationService,
      @Inject(MAT_DIALOG_DATA) public data: {email:string, idUser:number},
  )
  {
    this.form = new FormGroup({
      password: new FormControl("",[Validators.required, Validators.minLength(8)]),
      password_confirm: new FormControl("",[Validators.required, Validators.minLength(8)]),
    },
    {
      validators: [confirmPasswordValidator('password', 'password_confirm')]
    })
  }

  isInvalid(controlName: string)
  {
    const control = this.form.get(controlName)
    return control?.invalid && (control.dirty || control.touched)
  }

  isFormValid()
  { 
    return this.form.valid
  }

  closeDialog()
  {
    this.dialog.closeAll()
  }

  togglePasswordVisibility(event: Event)
  {
    if(event.target instanceof HTMLImageElement)
    { 
      const img: HTMLImageElement = event.target

      if( img.src.split("assets")[1] === this.hide_password.split("assets")[1] ) 
      {
        img.src = this.show_password
        event.target.previousElementSibling?.setAttribute("type","text")
      }
      else 
      {
        img.src = this.hide_password
        event.target.previousElementSibling?.setAttribute("type","password")
      }
    }
  }

  goToContactPage()
  {
    const link = document.createElement("a")

    link.href = environment.CONTACT_LINK
    link.target = "_blank"
    link.click()
  }

  async submit()
  {
    this.isSubmitted = true

    if( this.form.invalid || this.form.get('password')?.value == '' || this.form.get('password_confirm')?.value == '' )
    {
      this.form.markAsDirty()
      this.form.markAllAsTouched()
      console.log("invalid")
      return
    }

    const password = this.form.value.password

    const profil = 
    {
      email: this.data.email,
      password: password,
      idUser: this.data.idUser
    }

    this.profilService.saveProfil(profil).subscribe(data => {
      if(data == 1)
      {
          const snackBarRef = this.snackBar.open("Votre mot de passe à bien été enregistré", "OK", {
              duration: 5000,
          })

          sessionStorage.setItem("default_password", "false")
          snackBarRef.afterDismissed().subscribe(() => {
              this.closeDialog()
              return this.navigationService.navigateTo('', [null])
          })
      }
      else
      {
          this.snackBar.open("Une erreur est survenue lors de l'enregistrement de votre mot de passe, veuillez réessayer plus tard", "J'ai compris", {
              duration: 5000,
          })
      }
    })
  }
}

import { Component, SimpleChanges } from '@angular/core';
import { SharedService } from '../../../services/shared/shared.service';
import { SharedModule } from "../../../modules/shared/shared.module";
import { DashboardService } from '../../../services/dashboard/dashboard.service';
import { MessageIn } from '../../../interfaces/MessageIn';

@Component({
  selector: 'app-messages-non-lus',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './messages-non-lus.component.html',
  styleUrl: './messages-non-lus.component.css'
})
export class MessagesNonLusComponent 
{
  title:string = "Messages non lus" 
  pageSize:number = 7
  pageIndex:number = 1
  totalPages!:number
  totalResult!:number
  data_header_table = 
  {
      "badge":"",
      "date":"Date",
      "ref":"N°dossier",
      "expediteur":"Expéditeur",
      "msg":"Intitulé message",
      "etat":"État",
      "documents":"Documents"
  }
  data_body_table: MessageIn[] = []
  isDataReady:boolean = false
  id_user!:number

  constructor
  (
    private sharedService:SharedService,
    private dashboardService: DashboardService
  ) 
  {
    const idUser = this.sharedService.getIdUser()
    this.id_user = idUser
    this.createPagination(this.pageSize)
  }

  ngOnInit()
  {
    this.getData()
  }

  ngOnChanges(changes: SimpleChanges) 
  {
    if ('search' in changes) 
    {
      this.getData()
    }
  }

  changePage(currentPage:number, pageSize:number=this.pageSize, changeDisplay:boolean=false)
  {  
    this.pageIndex = changeDisplay == false ? currentPage : 1 
    this.pageSize = pageSize
    this.getData()
  }

  async createPagination(pageSize:number)
  {
    (await
      this.sharedService.getInfoPagination("messages_non_lus", this.id_user)).subscribe(data => {
      this.totalResult = data[0].total_result
      this.totalPages = Math.ceil(this.totalResult / pageSize)
    })
  }

  getData(search:string="")
  {
    this.dashboardService.getMessagesNonLus(this.pageIndex, this.pageSize, this.id_user, search).subscribe((data:MessageIn[]) => {
      this.data_body_table = data
      this.isDataReady = true
    })
  }
}

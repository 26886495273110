import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { NavigationService } from '../services/navigation/navigation.service';
import { dialogEmailText } from '../helpers/texts/texts';



@Component({
  standalone: true,
  imports: [],
  template: `
  <div>

    <form class="dialog d-flex border border-secondary">
      <div class="col-4 bg-blue white bold dialog-title">AJOUTER UNE ADRESSE EMAIL</div>
      <div class="col-8 bg-white blue-dark-1 dialog-content-group">
          <div class="dialog-content">
                ${dialogEmailText}
                <p>Veuillez cliquer sur le bouton ci-dessous pour accéder à votre page de profil et renseigner votre adresse email.</p>
              <button type="button" class="bg-blue white no-border py-2 px-3 mt-2 mx-1 float-end" (click)="goToProfil()">Voir mon profil</button>
          </div>
      </div>
    </form>
  </div>
  `
})

export class DialogEmail
{
    constructor
    (
        private navigationService: NavigationService,
        private dialog: MatDialog
    ) {}

    goToProfil()
    {
        this.navigationService.navigateTo("/profil", [null])
        return this.closeDialog()
    }

    closeDialog()
    {
        return this.dialog.closeAll()
    }
}

import { Autologin } from "../../interfaces/ConnexionOut";

export function isEmptyResult(data:Autologin): boolean
{
    const {id_user, profil, email, login, has_dossier, default_password, access_token, refresh_token} = data

    if(id_user == null && profil == null && email == null && login == null && has_dossier == null && default_password == null && access_token == null && refresh_token == null)
    {
        return true
    }
    else
    {
        return false
    }
}
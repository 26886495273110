import { Component, SimpleChanges} from '@angular/core';
import { DossiersService } from '../../../services/dossiers/dossiers.service';
import { SharedService } from '../../../services/shared/shared.service';
import { SharedModule } from '../../../modules/shared/shared.module';
import { MessageIn } from '../../../interfaces/MessageIn';
import { BreadcrumbComponent } from "../../../common/breadcrumb/breadcrumb.component";

@Component({
    selector: 'app-dossiers-messages',
    standalone: true,
    templateUrl: './dossiers-messages.component.html',
    styleUrl: './dossiers-messages.component.css',
    imports: [SharedModule, BreadcrumbComponent]
})
export class DossiersMessagesComponent 
{
  title:string = "Dossiers - Messages" 
  id_user:number
  pageSize:number = 7
  pageIndex:number = 1
  totalPages!:number
  totalResult!:number

  data_header_table:object = 
  {
    "badge":"",
    "date":"Date",
    "ref":"N°dossier",
    "expediteur":"Expéditeur",
    "msg":"Intitulé message",
    "etat":"État",
    "documents":"Documents"
  }
  data_body_table:MessageIn[] = []
  isDataReady:boolean = false

  constructor
  (
      private dossierService: DossiersService,
      private sharedService: SharedService
  )
  {
    this.id_user = this.sharedService.getIdUser()
    this.createPagination(this.pageSize)
  }

  ngOnInit()
  {
    this.getAllMessages()
  }

  ngOnChanges(changes: SimpleChanges) 
  {
    if ('search' in changes) 
    {
      this.getAllMessages()
    }
  }

  changePage(currentPage:number, pageSize:number=this.pageSize, changeDisplay:boolean=false)
  {  
    this.pageIndex = changeDisplay == false ? currentPage : 1 
    this.pageSize = pageSize
    this.getAllMessages()
  }

  async createPagination(pageSize:number)
  {
    (await
      this.sharedService.getInfoPagination("dossiers_messages", this.id_user)).subscribe(data => {
      this.totalResult = data[0].total_result
      this.totalPages = Math.ceil(this.totalResult / pageSize)
    })
  }

  getAllMessages(search:string="")
  {
      this.dossierService.getAllMessages(this.pageIndex, this.pageSize,  this.id_user, search).subscribe((data:MessageIn[]) => {
        this.data_body_table = data
        this.isDataReady = true
      })
  }
}

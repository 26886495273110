import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function confirmEmailValidator(emailControlName: string, confirmEmailControlName: string): ValidatorFn
{
  return (control: AbstractControl): ValidationErrors | null => {
    const email = control.get(emailControlName)?.value
    const confirmEmail = control.get(confirmEmailControlName)?.value

    if (email !== confirmEmail) 
    {
      // Return an error object with a key for the error and a value of true
      return { 'emailMismatch': true }
    }

    // If validation passes, return null
    return null
  }
}
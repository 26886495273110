export const environment = 
{
    production: false,
    BASE_URL: "https://api.preprodabonnes.mediation-eau.fr",
    HOST_SITE : "preprod.abonnes.mediation-eau.fr",
    LINK_SAISINE : "https://preprod.mediation-eau.fr/FR/votre-saisine/saisine.asp",
    ALLOWED_DOMAINS: ["preprod.abonnes.mediation-eau.fr"],
    LINK_GUIDE : "https://preprod.mediation-eau.fr/userfiles/files/guide/MDE_GuideUtilisation_EspaceAbonnesRepresentants.pdf",
    CONTACT_LINK: "https://preprod.mediation-eau.fr/FR/contact/abonne-et-requerant.asp"
}

import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConnexionService } from '../services/connexion/connexion.service';
import { CommonModule } from '@angular/common';
import { SharedService } from '../services/shared/shared.service';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { StorageService } from '../services/storage/storage.service';
import { NavigationService } from '../services/navigation/navigation.service';
import { Router } from '@angular/router';
import { dialogEmailText } from '../helpers/texts/texts';
import { confirmEmailValidator } from '../class/custom_validators/email-validators';
import { environment } from '../../environments/environment';
import { Password } from './password.component';


@Component({
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule],
  template: `
    <form class="dialog d-flex border border-secondary" [formGroup]="form">
      <div class="col-4 bg-blue white bold dialog-title">CONNEXION : <br><br> Ajouter une adresse email</div>
      <div class="col-8 bg-white blue-dark-1 dialog-content-group">
          <div class="dialog-content">
              ${dialogEmailText}
  
              <div class="d-flex flex-column">
                <span>Veuillez saisir votre email :</span>
                <br>
                <input type="email" class="my-2 p-1 pe-5 form-control" formControlName="email" oninput="this.value = this.value.trimStart()" required>

                <span class="red">
                  <em *ngIf="isSubmitted && isInvalid('email') && form.get('email')?.errors">
                    <p>* Veuillez entrer un email valide</p>
                  </em>

                  <em *ngIf="isSubmitted && form.hasError('emailMismatch')">
                    <p>* Les adresses email ne sont pas identiques</p>
                  </em>
                </span>
              </div>

              <div class="d-flex flex-column">
                <span>Confirmez votre email :</span>
                <br>
                <input type="email" class="my-2 p-1 pe-5 form-control" formControlName="email_confirm" oninput="this.value = this.value.trimStart()" required>

                <span class="red">
                  <em *ngIf="isSubmitted && isInvalid('email_confirm') && form.get('email_confirm')?.errors">
                    <p>* Veuillez entrer un email valide</p>
                  </em>

                  <em *ngIf="isSubmitted && form.hasError('emailMismatch')">
                    <p>* Les adresses email ne sont pas identiques</p>
                  </em>
                </span>
              </div>

              <div class="d-flex flex-column">
                <span>Veuillez saisir votre identifiant Médiation de l'eau reçu par courrier postal :</span>
                <br>
                <input type="text" class="my-2 p-1 pe-5 form-control" formControlName="login" oninput="this.value = this.value.trimStart()" required>

                <span class="red" *ngIf="isSubmitted && isInvalid('login') && form.get('login')?.errors">
                  <em>
                    <p>* Veuillez entrer un identifiant valide (Ex: REFERENCE-DOSSIER-2_premières_lettres_du_nom-2_premières_lettres_du_prénom)</p>
                  </em>
                </span>
              </div>

              <!-- <p>
                  Une fois connecté, nous vous conseillons fortement de modifier le mot passe reçu par courrier via la page mon profil de votre compte abonné
              </p> -->

              <small>
                Si vous n'avez pas d'identifiant Médiation de l'eau, vous pouvez utiliser le <a class="blue underline cursor" (click)="goToContactPage()">formulaire de contact de la Médiation de l'eau</a>
              </small>

              <br>

              <p class="result red" *ngIf="userNotFound">Aucun utilisateur n'est lié à cette adresse ou à cette référence dossier Médiation de l'eau</p>

              <p class="red" *ngIf="emailExists">
                * Cette adresse mail est déjà liée à un espace abonné.
                <br>
                En cas de besoin, vous pouvez utiliser le <a class="blue underline cursor" (click)="goToContactPage()">formulaire de contact de la Médiation de l'eau</a>
              </p>

              <div>
                <button 
                  type="button" 
                  class="bg-blue white no-border py-2 px-3 mt-2 mx-1 float-end" 
                  (click)="submit()">
                    Valider
                </button>

                <button 
                  type="button" 
                  class="bg-grey white no-border py-2 px-3 mt-2 mx-1 float-end" 
                  (click)="closeDialog()">
                    Fermer
                </button>
              </div>
          </div>
      </div>
    </form>
  `
})

export class NewEmail
{
    form!:FormGroup
    userNotFound:boolean = false
    isSubmitted:boolean = false
    emailExists:boolean = false

    constructor
    (
      private connexionService: ConnexionService,
      private sharedService: SharedService,
      private snackBar: MatSnackBar,
      private storageService: StorageService,
      private navigationService: NavigationService,
      private dialog: MatDialog,
      private router: Router
    )
    {
      this.form = new FormGroup({
        email: new FormControl("",[Validators.required, Validators.email , Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$')]),
        email_confirm: new FormControl("",[Validators.required, Validators.email , Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$')]),
        login: new FormControl("",[Validators.required]),
      },
      {
        validators: [confirmEmailValidator("email","email_confirm")]
      })
    }

    isInvalid(controlName: string)
    {
      const control = this.form.get(controlName)
      return control?.invalid && (control.dirty || control.touched)
    }

    isFormValid()
    { 
      return this.form.valid
    }

    closeDialog()
    {
      this.dialog.closeAll()
    }

    goToContactPage()
    {
      const link = document.createElement("a")

      link.href = environment.CONTACT_LINK
      link.target = "_blank"
      link.click()
      // return this.sharedService.goToContactPage()
    }

    async submit()
    {
      this.isSubmitted = true
      sessionStorage.clear()

      if( this.form.invalid || this.form.get('email')?.value == '' || this.form.get('login')?.value == '' )
      {
        this.form.markAsDirty()
        this.form.markAllAsTouched()
        console.log("invalid")
        return
      }

      const email = this.form.value.email?.trim()
      const login = this.form.value.login?.trim()
      let snackBarRef: MatSnackBarRef<TextOnlySnackBar>

      this.connexionService.checkIfUserExistsWithSameCredentials(email, login).subscribe(data => {
        this.emailExists = data[0].emailExist

        this.connexionService.getUserByEmailAndLogin(email, login).subscribe({
          next: (data) => 
          {
            if(!data) 
            {
              this.userNotFound = true
              return
            }
  
            if(!data.access_token || !data.refresh_token || !data.id_user ) 
            {
              this.snackBar
                .open(`Une erreur est survenue lors de la connexion`, `J'ai compris`)
              return
            }
        
            for (const property in data)
            {
              const propertyValue:string = property?.toString()
              this.storageService.set(property?.toString() , data[propertyValue]?.toString())
            }
      
            this.userNotFound = false
      
            const token = data.access_token

            sessionStorage.removeItem("email")
            sessionStorage.removeItem("login")
            
            if( token && this.connexionService.isTokenExpired() == false ) 
            {
              sessionStorage.removeItem("loginIsNotAnEmail")
              this.closeDialog()
              snackBarRef = this.snackBar.open("Votre email à bien été enregistré comme identifiant", "Ok")
  
              this.sharedService.postTableConnexion(data.id_user, 1, data.id_user).subscribe(() => {
                if(data.default_password == true)
                {
                  const disableClose = true
                  return this.sharedService.openDialog(Password, {email:email, idUser:data.id_user}, disableClose)
                }
                else
                {
                  return this.navigationService.navigateTo('', [null])
                }
              })
            }
  
            // Utilisation du timeout car sur firefox, si on utlise la prorpiété 'duration' de 'this.snackBar.open', le changement de page est bloqué
            setTimeout(() => {
              snackBarRef.dismiss()
            }, 3000)
            
            this.sharedService.postTableConnexion(data.id_user, 15, data.id_user).subscribe()
          },
          error: (e:Error) => 
          {
            console.log(e)
          }
        })
      })
    }
}

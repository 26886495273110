import { Component, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Document } from '../interfaces/Document';
import { DossiersService } from '../services/dossiers/dossiers.service';
import { DownloadService } from '../services/download/download.service';
import { SharedModule } from '../modules/shared/shared.module';
import { MatPaginator } from '@angular/material/paginator';
import { Dossiers_Data } from '../interfaces/Dossiers';
import { ConvertSizePipe } from "../helpers/pipes/convert-size/convert-size.pipe";
import { SharedService } from '../services/shared/shared.service';

@Component({
    selector: 'app-document',
    standalone: true,
    template: `
      @if( !dataSource )
      {
        <div class="d-flex justify-content-center mt-3">
          <mat-spinner></mat-spinner>
        </div>
      }
      @else
      {
        <div class="mat-elevation-z0 border document-table-wrapper">
          <table mat-table [dataSource]="dataSource" class="document-table">
        
            <ng-container matColumnDef="badge">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <span class="badge badge-pill align-self-start me-1 white bg-blue" [ngClass]="element.non_lu ? 'opacity-100': 'opacity-0'">N</span> 
              </td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>Date</th>
              <td mat-cell *matCellDef="let element" class="date">{{ element.date | date:'dd-MM-yyyy' }}</td>
            </ng-container>

            <ng-container matColumnDef="expediteur">
              <th mat-header-cell *matHeaderCellDef>Expéditeur</th>
              <td mat-cell *matCellDef="let element" class="expediteur">{{ element.expediteur }}</td>
            </ng-container>

            <ng-container matColumnDef="etat">
              <th mat-header-cell *matHeaderCellDef>État</th>
              <td mat-cell *matCellDef="let element" class="etat">{{ element.etat }}</td>
            </ng-container>

            <ng-container matColumnDef="msg">
              <th mat-header-cell *matHeaderCellDef>Rattaché au message</th>
              <td mat-cell *matCellDef="let element" class="msg">{{ element.msg }}</td>
            </ng-container>

            <ng-container matColumnDef="fichier">
              <th mat-header-cell *matHeaderCellDef>Fichier</th>
              <td mat-cell *matCellDef="let element" class="fichier">
                <a
                  class="blue cursor"
                  (click)="
                    download(this.data.ref, idUser, element.id_document, element.url_doc);
                    element.non_lu ? markAsRead(element.id_document) : ''
                  ">{{ element.fichier }}
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="poids">
              <th mat-header-cell *matHeaderCellDef>Poids</th>
              <td mat-cell *matCellDef="let element" class="poids">{{ element.poids | convertSize }}</td>
            </ng-container>

            <ng-container matColumnDef="telecharger">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element" class="telecharger" 
                (click)="
                  download(this.data.ref, idUser, element.id_document, element.url_doc);
                  element.non_lu ? markAsRead(element.id_document) : ''
                ">
                  <img class="table-icons cursor" src='/assets/icons/picto-telecharger.png' *ngIf="element.is_downloadable">
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-table-details"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="content-table-details" [ngClass]="row.non_lu ? 'blue': ''"></tr>
            </table>
        </div>
      }
  `,
    imports: [SharedModule, ConvertSizePipe]
})

export class DocumentComponent 
{
  @ViewChild(MatPaginator, {static:true}) paginator!: MatPaginator
  @Input() data!:Dossiers_Data

  title:string = 'documents'
  date:Date = new Date()
  formatedDate = this.date.toLocaleDateString().split("/").join('-')
  displayedColumns: string[] = ["badge","date","expediteur","etat","msg","fichier","poids","telecharger"]
  ELEMENT_DATA:Document[] = []
  dataSource!:MatTableDataSource<Document>
  idUser!:number
  ref!:string

  constructor
  (
    private dossierService: DossiersService,
    private downloadService: DownloadService,
    private sharedService: SharedService
  )
  {
    const id_user = this.sharedService.getIdUser()
    this.idUser = id_user

    this.ref = this.sharedService.getParam('ref')?.toString() || ""
  }

  ngOnInit()
  {
    this.getDocuments()

    setInterval(() => {
      this.getDocuments()
    }, 1000 * (2 *60))
  }

  getDocuments()
  {
    const id_dossier = Number(this.sharedService.getParam("id_dossier"))

    this.dossierService.getDocuments(this.ref, id_dossier, this.idUser).subscribe(data => {
      this.ELEMENT_DATA = data
      this.dataSource = new MatTableDataSource<Document>(this.ELEMENT_DATA)
    })
  }

  markAsRead(id_document:number)
  {
    this.dossierService.updateDocument([id_document]).subscribe(data => {
      if(data) 
      {
        this.sharedService.postTableConnexion(this.idUser, 502, id_document).subscribe()
        this.getDocuments()
      }
    })
  }


  download(ref:string, id_user:number=0, id_document:number, url_doc:string)
  {
    const fileName = url_doc.split("/").slice(-1)[0]

    this.downloadService.downloadProcess(ref, fileName, 'dossiers', id_user, id_document)
  }
}
